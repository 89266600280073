<template>
  <div class="custom-dashboard-content-holder" :class="{ standalone }">
    <div ref="target" />
  </div>
</template>

<script>
import Highframe from 'highframe'
// :data-user="user"
// :data-api-key="apiKey"
import { mapGetters } from 'vuex'
export default {
  props: {
    standalone: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  highframe: null,
  listeners: [],
  rootListeners: [],
  data() {
    return {
      loaded: false,
      ready: false
    }
  },
  computed: {
    ...mapGetters({
      clientAccount: 'Auth/getUser',
      isAdmin: 'Auth/isAdmin',
      currentLocation: 'Config/getCurrentLocation',
      token: 'Auth/getAuthToken'
    }),
    dashboard() {
      return this.$store.getters['Config/getCustomDashboard'](
        this.$route.params.name
      )
    }
  },
  mounted() {
    this.$options.highframe = Highframe.create(this.$refs.target, {
      sandbox: 'allow-same-origin allow-scripts allow-downloads',
      allowFullscreen: true,
      allowPaymentRequest: false,
      src: this.dashboard.url,
      name: this.dashboard.name,
      childOrigin: Highframe.parseOrigin(this.dashboard.url)
    })

    this.$options.highframe.once('loaded', () => {
      this.loaded = true
    })

    const dispatch = () => {
      this.$options.highframe.dispatch('model', {
        currentLocation: this.currentLocation,
        isAdmin: this.isAdmin,
        clientAccount: this.clientAccount,
        // DANGER DANGER: we should not pass the token unless explicitly whitelisted
        token: this.dashboard.pass_token === true ? this.token : undefined
      })
    }

    this.$options.highframe.once('ready', () => {
      this.ready = true
      dispatch()
    })

    this.$emitter.on(
      ...this.attachRootListener('refresh-requested', () => {
        dispatch()
      })
    )
  },
  beforeUnmount() {
    this.$options.rootListeners.forEach(({ event, listener }) => {
      this.$emitter.off(event, listener)
    })

    if (!this.$options.highframe) return

    this.$options.highframe.destroy()
  },
  methods: {
    attachRootListener(event, listener) {
      this.$options.rootListeners.push({
        event,
        listener
      })
      return [event, listener]
    }
  }
}
</script>

<style scoped>
.custom-dashboard-content-holder,
.custom-dashboard-content-holder > * {
  width: 100%;
  height: 100%;
}

/*
NOTE: fixes issue on iOS regarding iFrames not being scrollable via touch
more info here: https://davidwalsh.name/scroll-iframes-ios
*/
.custom-dashboard-content-holder {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
}

.custom-dashboard-content-holder {
  background: white;
}
</style>
